import React from 'react';
import './_timeOutPlaceholder.scss';

export const TimeOutPlaceholder = ({ title, subtitle }) => {
    
    return (
        <img 
            decoding="async" 
            width="980" 
            height="546" 
            src="https://puromed.pl/wp-content/uploads/2025/01/poradnia-zamkniecie.jpg" 
            alt="" 
            title="poradnia-zamkniecie" 
            srcSet="https://puromed.pl/wp-content/uploads/2025/01/poradnia-zamkniecie.jpg 980w, https://puromed.pl/wp-content/uploads/2025/01/poradnia-zamkniecie-300x167.jpg 300w, https://puromed.pl/wp-content/uploads/2025/01/poradnia-zamkniecie-768x428.jpg 768w, https://puromed.pl/wp-content/uploads/2025/01/poradnia-zamkniecie-480x267.jpg 480w" 
            sizes="(max-width: 980px) 100vw, 980px" 
            className="wp-image-11572" 
        />
    );
}

export default TimeOutPlaceholder;